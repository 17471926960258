<template>
  <svg
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.49902 22.9999V21.6249C1.49902 16.3092 5.97618 12 11.499 12C17.0219 12 21.499 16.3092 21.499 21.6249V22.9999"
      stroke="white"
      stroke-width="1.8"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="parent-hover:fill-primary"
    />
    <path
      d="M11.4994 11.9999C14.6553 11.9999 17.2137 9.53746 17.2137 6.49997C17.2137 3.46242 14.6553 1 11.4994 1C8.34353 1 5.78516 3.46242 5.78516 6.49997C5.78516 9.53746 8.34353 11.9999 11.4994 11.9999Z"
      stroke="white"
      stroke-width="1.8"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="parent-hover:fill-primary"
    />
  </svg>
</template>

<style scoped>
.parent-profile:hover .parent-hover\:fill-primary {
  stroke: #FFBA8D;
}
</style>
