<template>
  <header
    class="w-full bg-primary-400 py-4 lg:pt-6 lg:pb-7 lg:relative sticky top-0 z-50 transition-all duration-500"
  >
    <section class="container px-4 xl:px-0 block lg:hidden">
      <div class="w-full flex flex-row space-x-3">
        <div class="w-full">
          <p-search-input-modal class="w-full !h-11"></p-search-input-modal>
        </div>

        <nuxt-link to="/" class="block !w-11">
          <div
            class="rounded-lg flex items-center w-11 h-11 justify-center bg-white"
          >
            <img :src="MiniLogo" class="object-cover w-7 h-7" />
          </div>
        </nuxt-link>
      </div>
    </section>
    <section class="hidden lg:block">
      <section class="container flex justify-between items-center flex-row">
        <section class="flex flex-col">
          <div>
            <nuxt-link
              to="/"
              class="flex flex-row space-x-6 items-center place-content-start"
            >
              <p-icon-logo class="h-10" />
              <div class="text-4xl font-lato font-semibold text-white">
                Позитив
              </div>
            </nuxt-link>
          </div>
          <div class="pt-5" :class="`min-h-[${scroll}px] `">
            <p-dropdown-button
              class="relative z-40"
              @click="isActive = !isActive"
              :isActive="isActive"
            >
              <div>Каталог</div>
              <p-icon-more class="h-4 pb-1" :isActive="isActive" />
            </p-dropdown-button>
            <div class="absolute w-full z-10 top-[164px] container">
              <p-catalog
                @close="close"
                :categoriesList="categoriesList.categories"
                v-if="isActive"
                :isActive="isActive"
              />
            </div>
          </div>
        </section>
        <section class="grid gap-5">
          <div class="hidden lg:block">
            <div
              class="flex flex-row space-x-16 desktop:space-x-24 h-10 items-center"
            ></div>
          </div>
          <p-search-input />
        </section>
        <section class="hidden lg:block">
          <div class="flex flex-col space-y-12 items-end">
            <div class="flex flex-row space-x-5 h-5 pl-10">
              <!-- <a
                href="https://www.instagram.com/positive_group_"
                target="_blank"
              >
                <p-icon-instagram class="parent-instagram cursor-pointer" />
              </a> -->
              <!-- <p-icon-facebook class="parent-facebook cursor-pointer" />
              <p-icon-tiktok class="parent-tiktok cursor-pointer" /> -->
              <a href="tel:+79409578812" target="_blank">
                <p
                  class="cursor-pointer pt-0.5 text-base font-lato text-white hover:text-secondary-200"
                >
                  +7 (940) 957-88-12
                </p>
              </a>
            </div>
            <div class="flex flex-row space-x-8">
              <nuxt-link to="/favorites">
                <div class="relative">
                  <p-icon-favorite class="parent-favorite cursor-pointer" />
                  <div
                    v-if="favoritesList.length"
                    class="absolute font-lato left-4 leading-3 -top-2 bg-secondary-300 flex items-center border-2 border-primary-400 text-white rounded-full min-w-5 h-5 font-medium px-1 justify-center"
                    style="font-size: 10px"
                  >
                    {{ favoritesList.length }}
                  </div>
                </div>
              </nuxt-link>
              <nuxt-link to="/cart">
                <div class="relative">
                  <p-icon-cart class="parent-cart cursor-pointer" />
                  <div
                    v-if="cartLength"
                    class="absolute font-lato left-4 leading-3 -top-2 bg-secondary-300 flex items-center border-2 border-primary-400 text-white rounded-full min-w-5 h-5 font-medium px-1 justify-center"
                    style="font-size: 10px"
                  >
                    {{ cartLength }}
                  </div>
                </div>
              </nuxt-link>
              <nuxt-link to="/user">
                <div class="relative">
                  <p-icon-profile class="parent-profile cursor-pointer" />
                </div>
              </nuxt-link>
              <!-- <div class="relative">
                <p-icon-profile class="parent-profile cursor-pointer" />
                <p-dropdown-wrapper
                  class="absolute right-0 top-10 w-62 h-80 rounded-[16px]"
                  v-if="isAuth"
                >
                  <div>
                    <ul
                      class="text-lg text-lato px-10 py-10 flex flex-col gap-5"
                    >
                      <li><nuxt-link to=""> Заказы </nuxt-link></li>
                      <li><nuxt-link to="">Обратная связь</nuxt-link></li>
                      <li><nuxt-link to="">Избранное</nuxt-link></li>
                      <li><nuxt-link to="">Сборки мебели</nuxt-link></li>
                      <li><nuxt-link to="">Контрагенты</nuxt-link></li>
                      <li><nuxt-link to="">Настройки профиля</nuxt-link></li>
                    </ul>
                  </div>
                </p-dropdown-wrapper>
              </div> -->
            </div>
          </div>
        </section>
      </section>
    </section>
  </header>
</template>

<script setup>
import { useWindowSize } from "@vueuse/core";
import { useFavoritesStore } from "~/stores";
import { useCategoryStore } from "~/stores/use-categories-store";
import { getCategoriesTree } from "~/utils/helpers";
import { ref } from "vue";
import { useRouter } from "vue-router";
import MiniLogo from "~/assets/img/mini-logo.png";
const route = useRoute();

const { length: cartLength } = useCart();

const favoritesList = computed(() => {
  const { favorites } = useFavoritesStore();
  return favorites;
});

const { $get } = useAxios();
const isMainPage = computed(() => {
  if (route.path === "/" || isActive.value) {
    return true;
  } else return false;
});

const categoriesList = useCategoryStore();
const closeAction = ref(false);

const isActive = ref(false);
const scroll = ref(0);

const { width } = useWindowSize();
const isAuth = ref(false);
const headerVisible = ref(true);

const handleScroll = () => {
  if (width.value < 1024) {
    headerVisible.value = false;
    const scrollY = window.scrollY;
    headerVisible.value = scrollY <= 0;
  } else {
    headerVisible.value = true;
  }
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

function close() {
  isActive.value = false;
}

// const handleWheel = (event) => {
//   if (event.deltaY > 0 && width.value < 1024) {
//     headerVisible.value = false;
//   } else {
//     headerVisible.value = true;
//   }
// };

// onMounted(() => {
//   window.addEventListener("wheel", handleWheel);
// });
const selectedCategory = ref("");
const router = useRouter();

const selectCategory = (category) => {
  selectedCategory.value = category;
  router.push({ path: "/information", query: { category } });
};
</script>
